import { Injectable } from '@angular/core';
import { environment } from '../../../../src/environments/environment';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class StripeService {
  public pk: string = environment
  .pkStripe; //Production stripe key
  constructor(private toastr: ToastrService) { }

  createSetupIntent(token: string){
    let promise = new Promise((res, rej) =>{

      fetch( environment.apikamgus_base_url + environment.users.card.setup_intent , {
		      method: 'POST',
		      //body: JSON.stringify({user_id: token}),
		      headers:{
		        'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
		      }
		    }).then((res:any) => res.json())
		    .catch((error:any) => {
		      console.error('Error:', error)
		      this.toastr.error(JSON.stringify(error), "Error creando setup intent")    
          rej("Error creando setup intent");
          
		    })
		    .then((response:any) => {
		      if(response.error && (response.error.code != 200)){
            this.toastr.error(response.error.msg, "Error");
            rej(response.error.msg);
            return;
          }
          //this.presentModal(null, response.data.client_secret);
          res(response.data.client_secret);
		      
		    });
    });
    return promise;
  }
  
}
 