import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpParams } from '@angular/common/http';
import { ApiService } from '../../services/api/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.css']
})
export class NewPasswordComponent implements OnInit {

  formData : FormGroup;
  isEqual  : boolean = true;
  token    : string;
  msg      : string;
  warning  : string;
  isLoading: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private _api: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService
    ) { }

  ngOnInit() {
    this.token = this.route.snapshot.params['token']
    this.loadForm();
  }

  loadForm() {
    this.formData = this.formBuilder.group({
      password: ["", [Validators.required]],
      retypePassword: ["", [Validators.required]],
    });
  }

  send() {

    this.isLoading = true;

    const params = new HttpParams()
    .set("token", this.token)
    .set("password", this.formData.value.password)
    .set("password_confirmation", this.formData.value.retypePassword);

    this._api.resetPassword(params).subscribe(data => {

      if(data.warning){
        this.isLoading = false;
        this.warning = data.warning;

      }else{
        this.isLoading = false;
        this.warning = '';
        this.toastr.success(data.msg);
        this.router.navigate(["/login"]);
      }

    });

  }

  onKey(event: any) { // without type info
    
    console.log(event);
    const password = this.formData.value.password;
    const retypePassword  = event.target.value;

    if(password == retypePassword){
      this.isEqual = true;
    }else{
      this.isEqual = false;
    }

  }

}
