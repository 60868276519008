import { Component } from "@angular/core";
import { SettingsService } from "./services/service.index";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  title = "MyApp";
  theme: string;
  constructor(private _ajustes: SettingsService) {
    this.theme = this._ajustes.ajustes.temaUrl;
  }
}
