<div class="row cont-login login-user">
    <div class="col-md-6 pd-forms-login text-center no-display-movil" *ngIf="step === 1">
        <img class="img-80" src="../../assets/images/logo-white2.png" />
        <div class="spacer-100"></div>
        <h1 class="color-white | titulo-acarreo"><b>ACARREO Y MUDANZA</b></h1>
        <p class="color-white p-login">
            Queremos ofrecerte el mejor servicio de traslado de grandes cargas al mejor precio. Nos preocupamos por tu comodidad y por ellos hemos creado Kamgus para ti.
        </p>
    </div>

    <div class="col-md-6 pd-forms-login text-center no-display-movil" *ngIf="step === 2">
        <img class="img-80" src="../../assets/images/logo-white2.png" />
        <div class="spacer-100"></div>
        <h4 class="color-white | titulo-confirmar"><strong>Confirmar</strong></h4>
        <p class="color-white p-login">
            Confirma los datos de tu suscripción y ya podrá empezar a disfrutar de todos los beneficios que Kamgus tiene para ti en materia de traslados.
        </p>
    </div>

    <div class="col-md-6 cont-white pd-forms-register contRegUser">
        <div class="card">
            <form class="form-horizontal form-material" [formGroup]="formData" *ngIf="formData && !registerOK && step === 1">
                <h1 class="
            box-title
            m-t-0 m-b-0
            color-blue
            |
            text-center
            |
            text-registrar
          ">
                    REGISTRATE GRATIS
                </h1>

                <div class="alert alert-danger" *ngIf="errorMessage !== undefined">
                    <i class="ti-close"></i> {{ errorMessage.error.msg }}
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
                </div>

                <!--  <div class="form-group ">
                        <div class="col-xs-12">
                            <select class="form-control" placeholder="Soy" required [(ngModel)]="typeUser" (change)="selectUser( $event.target.value )" [ngModelOptions]="{standalone: true}">
                                <option value="">Soy...</option>
                                <option value="user">Soy usuario</option>
                                <option value="company">Soy Empresa</option>
                            </select>
                            <div *ngIf="typeUser === ''" class="invalid-feedback">
                               Seleccione un tipo de usuario
                            </div>
                        </div>
                    </div> -->

                <div class="form-group" *ngIf="typeUser === 'company'">
                    <div class="col-xs-12">
                        <label class="color-blue label-login">Nombre empresa</label>
                        <input class="form-control input-register" type="text" required="" placeholder="Nombre de la empresa" formControlName="company" />
                        <div *ngIf="submitted && f.company.errors" class="invalid-feedback">
                            <div *ngIf="f.company.errors.required">
                                * El nombre de la empresa es requerido
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <div class="col-xs-12">
                        <label class="color-blue label-login">Nombre de contacto</label>
                        <input class="form-control input-register" type="text" required="" placeholder="Nombre de contacto" formControlName="nombre" />
                        <div *ngIf="submitted && f.nombre.errors" class="invalid-feedback">
                            <div *ngIf="f.nombre.errors.required">
                                * El nombre es requerido
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <div class="col-xs-12">
                        <label class="color-blue label-login">Apellido</label>
                        <input class="form-control input-register" type="text" required="" placeholder="Apellido" formControlName="apellido" />
                        <div *ngIf="submitted && f.apellido.errors" class="invalid-feedback">
                            <div *ngIf="f.apellido.errors.required">
                                * El Apellido es requerido
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <div class="col-xs-12">
                        <label class="color-blue label-login">Teléfono</label>
                        <input class="form-control input-register" mask="0000-0000" prefix="+507 " [showMaskTyped]="false" type="text" required="" placeholder="Teléfono celular" formControlName="celular" />
                        <div *ngIf="submitted && f.celular.errors" class="invalid-feedback">
                            <div *ngIf="f.celular.errors.required">
                                * El celular es requerido
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <div class="col-xs-12">
                        <label class="color-blue label-login">Correo</label>
                        <input class="form-control input-register" type="text" required="" placeholder="Email" formControlName="email" (input)="inputValidator($event)" />
                    </div>
                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                        <div *ngIf="f.email.errors.required">* El email es requerido</div>
                    </div>
                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                        <div *ngIf="f.email.errors.email">
                            * No es un formato de correo válido
                        </div>
                    </div>
                    <div class="invalid-feedback" *ngIf="emailRegistrado">
                        * Email o Teléfono Registrado intente con otro
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-xs-12">
                        <label class="color-blue label-login">Confirmar correo</label>
                        <input class="form-control input-register" type="text" required="" placeholder="Confirmar correo" formControlName="reTypeEmail" (input)="inputValidator($event)" />

                        <div *ngIf="submitted && f.reTypeEmail.errors" class="invalid-feedback">
                            <div *ngIf="f.reTypeEmail.errors.required">
                                * El correo es requerido
                            </div>
                        </div>
                        <div *ngIf="submitted && f.reTypeEmail.errors" class="invalid-feedback">
                            <div *ngIf="f.reTypeEmail.errors.reTypeEmail">
                                * No es un formato de correo válido
                            </div>
                        </div>

                        <div *ngIf="submitted && f.email.value !== f.reTypeEmail.value" class="invalid-feedback">
                            Correos no coinciden
                        </div>
                    </div>
                </div>

                <div class="form-group row ml-1">
                    <div class="col-md-12">
                        <div class="checkbox checkbox-primary p-t-0 terminos">
                            <input id="checkbox-signup" type="checkbox" formControlName="terms" />
                            <label for="checkbox-signup">
                Acepto
                <a href="https://kamgus.cf/terminos-y-condiciones-empresa/"
                  >términos y condiciones</a
                ></label
              >
              <div *ngIf="submitted && f.terms.errors" class="invalid-feedback">
                <div *ngIf="f.terms.errors.required && !f.terms.value">
                  Aceptar términos y condiciones
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>

      <form
        class="form-horizontal form-material"
        [formGroup]="formDataEmail"
        *ngIf="formDataEmail && !registerOK && step === 2"
      >
        <h3
          class="
            box-title
            m-t-40 m-b-0
            color-blue
            text-center
            |
            text-confirmatucorreo
          "
        >
          CONFIRMA TU CORREO
        </h3>
        <br />
        <div class="card-pricing text-beneficios">
          <h3 class="box-title color-blue text-center | text-beneficioskamgus">
            <small>Beneficios de Kamgus</small>
          </h3>
          <ul>
            <li>Carga asegurada hasta 10.000 USD</li>
            <li>Más de 200 conductores en linea</li>
            <li>Seguimiento en línea</li>
            <li>Pago en efectivo o tarjeta</li>
            <li>Marketplace disponibles</li>
            <li>Elige el precio de tu servicio</li>
          </ul>
        </div>

        <div class="form-group m-t-20">
          <div class="col-xs-12">
            <label class="color-blue label-login">Contraseña</label>
                            <show-hide-password class="btn_ojo" size="sm" icon="empty" btnStyle="secondary" [btnOutline]="true">
                                <input class="form-control input-register" type="password" required="" placeholder="" formControlName="password" />
                            </show-hide-password>
                            <div *ngIf="submitted && g.password.errors" class="invalid-feedback">
                                <div *ngIf="g.password.errors.required">
                                    * La contraseña es requerida
                                </div>
                            </div>
                            <div *ngIf="submitted && g.password.value !== g.retypePassword.value" class="invalid-feedback">
                                Contraseñas no coinciden
                            </div>
                        </div>
                    </div>

                    <div class="form-group m-t-20">
                        <div class="col-xs-12">
                            <label class="color-blue label-login">Confirmar Contraseña</label>
                            <show-hide-password class="btn_ojo" size="sm" icon="empty" btnStyle="secondary" [btnOutline]="true">
                                <input class="form-control input-register" type="password" required="" placeholder="" formControlName="retypePassword" />
                            </show-hide-password>

                            <div *ngIf="submitted && g.retypePassword.errors" class="invalid-feedback">
                                <div *ngIf="g.retypePassword.errors.required">
                                    * La constraseña es requerida
                                </div>
                            </div>
                            <div *ngIf="submitted && g.password.value !== g.retypePassword.value" class="invalid-feedback">
                                Contraseñas no coinciden
                            </div>
                        </div>
                        <!--
          <div class="checkbox checkbox-primary p-t-0">
            <input
              id="checkbox-signup"
              type="checkbox"
              formControlName="terms"
            />
            <label for="checkbox-signup"
              ><a href="#" style="color: #000">
                Acepto términos y condiciones</a
              ></label
            >
            <div *ngIf="submitted && f.terms.errors" class="invalid-feedback">
              <div *ngIf="f.terms.errors.required && !f.terms.value">
                Aceptar términos y condiciones
              </div>
            </div>
          </div>-->
                    </div>
            </form>

            <div class="form-group text-center contenedorbtnRegUser">
                <div class="col-xs-12 mb-3">
                    <button *ngIf="step === 1" class="
                        btn
                        waves-effect waves-light
                        btn-rounded btn-warning btn-block
                        " type="submit" (click)="next(step)"
                        [disabled]="isLoading"
                        >
                            Siguiente 1 / 3
                        <div *ngIf="isLoading" class="lds-dual-ring"></div>
                    </button>
            <button *ngIf="step === 2" class="
                btn
                waves-effect waves-light
                btn-rounded btn-warning btn-block
                " type="submit" (click)="next(step)"
                [disabled]="isLoading"
                >
                Siguiente 2 / 3

                <div *ngIf="isLoading" class="lds-dual-ring"></div>
            </button>
                </div>

                <div class="col-xs-12" *ngIf="step === 1">
                    <a href="javascript:void(0)" class="
              btn
              waves-effect waves-light
              btn-rounded
              btn-warning__social
              btn-block
            ">
                        <i aria-hidden="true" class="fa fa-facebook" style="margin-right: 20px"></i> Registrarme con Facebook
                    </a>
                </div>
            </div>
            <div class="orm-group text-center contenedorbtnRegUser">
                <a routerLink="/">Ya tengo usuario</a>
            </div>
            <!--   <div class="form-group m-b-0">
                        <div class="col-sm-12 text-center">
                            <p>Ya tengo cuenta <a href="" routerLink="/login" class="text-info m-l-5"><b>Ingresar</b></a></p>
                        </div>
                    </div> -->
            </div>
        </div>
    </div>