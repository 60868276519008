<h3>{{leyenda}}</h3>

<div class="input-group">
    <div class="input-group-btn">
        <button class="btn btn-primary" type="button" (click)="barra(-5)">
          <i class="mdi mdi-minus"></i>
        </button>
    </div>

    <input type="text" class="form-control" [(ngModel)]="progress">

    <div class="input-group-btn">
        <button class="btn btn-primary" type="button" (click)="barra(5)">
            <i class="mdi mdi-plus"></i>
        </button>
    </div>
</div>