<div class="row">
    <div class="col-6">
        <div class="card">
            <div class="card-body">

                <app-graficos [doughnutChartLabels]="graficos.grafico1.labels" [doughnutChartData]="graficos.grafico1.data" [leyenda]="graficos.grafico1.leyenda"></app-graficos>


            </div>
        </div>
    </div>
    <div class="col-6">
        <div class="card">
            <div class="card-body">

                <app-graficos [doughnutChartLabels]="graficos.grafico2.labels" [doughnutChartData]="graficos.grafico2.data" [leyenda]="graficos.grafico2.leyenda"></app-graficos>


            </div>
        </div>
    </div>
    <div class="col-6">
        <div class="card">
            <div class="card-body">

                <app-graficos [doughnutChartLabels]="graficos.grafico3.labels" [doughnutChartData]="graficos.grafico3.data" [leyenda]="graficos.grafico3.leyenda"></app-graficos>


            </div>
        </div>
    </div>
    <div class="col-6">
        <div class="card">
            <div class="card-body">

                <app-graficos [doughnutChartLabels]="graficos.grafico4.labels" [doughnutChartData]="graficos.grafico4.data" [leyenda]="graficos.grafico4.leyenda"></app-graficos>


            </div>
        </div>
    </div>

</div>