import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-thankyou-page',
  templateUrl: './thankyou-page.component.html',
  styleUrls: ['./thankyou-page.component.css']
})
export class ThankyouPageComponent implements OnInit {

  web;
  google;
  ios;
  constructor() { }

  ngOnInit() {
    this.web = environment.web;
    this.google = environment.googleDriver;
    this.ios = environment.iosDriver;
  }

}
