<!-- ============================================================== -->
<!-- Left Sidebar - style you can find in sidebar.scss  -->
<!-- ============================================================== -->
<aside class="left-sidebar">
    <!-- Sidebar scroll-->
    <div class="scroll-sidebar">
        <!-- Sidebar navigation-->
        <nav class="sidebar-nav">
            <ul id="sidebarnav">
                <!--<li class="user-profile">
                    <a class="has-arrow waves-effect waves-dark" href="#" aria-expanded="false"><img src="../assets/images/users/profile.png" alt="user" /><span class="hide-menu">{{ user.nombre }} {{ user.apellidos }}</span></a>
                    <ul aria-expanded="false" class="collapse">
                        <li><a routerLink="/profile">Perfil</a></li>
                        <li><a routerLink="/wallet/method-payment">Billetera</a></li>
                        <li><a routerLinkActive="active" routerLink="/account-settings">Opciones</a></li>
                        <li><a (click)="logOut()">Salir</a></li>
                    </ul>
                </li>
                <li class="nav-devider"></li>
                <li class="nav-small-cap">PERSONAL</li>-->

                <li *ngFor="let menu of menu">
                    <a class="waves-effect waves-dark"  aria-expanded="false" [routerLink]="menu.url" *ngIf="menu.url !== '' ">
                        <i class="{{menu.icon}}"></i>
                        <span class="hide-menu">{{menu.titulo}}
                        </span>
                    </a>
                    <a class="has-arrow waves-effect waves-dark"  aria-expanded="false" *ngIf="menu.url === '' ">
                        <i class="{{menu.icon}}"></i>
                        <span class="hide-menu">{{menu.titulo}} 
                            <!-- <span class="label label-rouded label-themecolor pull-right">{{menu.submenu.length}}</span> -->
                        </span>
                    </a>
                    <ul aria-expanded="false" class="collapse">
                        <li *ngFor="let submenu of menu.submenu">
                            <a routerLinkActive="active" routerLink="{{submenu.url}}">{{submenu.titulo}} </a>
                        </li>
                        
                    </ul>
                </li>
                <li class="version-app">
                    <a class="waves-effect waves-dark"  aria-expanded="false" >
                        <i class="mdi mdi-chart-bubble"></i>
                        <span class="hide-menu">Versión: <span class="font-weight-bold">{{version}}</span></span>
                    </a>
                </li>
            </ul>
        </nav>
        <!-- End Sidebar navigation -->
    </div>
    <!-- End Sidebar scroll-->
</aside>
<!-- ============================================================== -->
<!-- End Left Sidebar - style you can find in sidebar.scss  -->
<!-- ============================================================== -->