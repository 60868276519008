import { HttpClient } from "@angular/common/http";
import { Injectable} from "@angular/core";
import { environment } from "../../../environments/environment";
// import { BaseService } from "../commons/base.service";
import { tap } from "rxjs/operators";
import { Login } from "../../interfaces/LoginInterface";
import { UserCompany } from '../../interfaces/UserCompany';

const base_url = environment.genericThird;
const apikamgus_base_url = environment.apikamgus_base_url;
@Injectable()
export class LoginService {


  constructor(public http: HttpClient) {}

  login(params) {
    return this.http.post(environment.login, params);
  }

  /** Inicio de Sesión */
  loginNew(params) {

    const url = `${apikamgus_base_url}/login`;

    return this.http.post<Login>(url, params).pipe(
      tap((resp: Login) => {
        localStorage.setItem("token", JSON.stringify(resp.token));
        localStorage.setItem("role", JSON.stringify(resp.role));
        return resp;
      })
    );
  }

  /** Obtener Paises */
  paises(url) {
    return this.http.get(url);
  }

  licences() {
    return this.http.get(environment.licences);
  }

  register(params) {
    return this.http.post(environment.register, params);
  }

  /** Registrar nuevo usuario */
  crearUsuario(params) {
    return this.http.post(environment.crearUsuario, params);
  }

  /** Registrar nuevo usuario desde Administrador */
  crearUser(params) {   
    return this.http.post(environment.crearUser, params);
  }

  registerCompanyUser(params){

    const url =  `${apikamgus_base_url}/registration/companies`;

    return this.http.post<UserCompany>(url, params).pipe(
      tap((resp: any) => {
        return resp;
      })
    );

  }


}
