import { RouterModule } from "@angular/router";
import { PagesComponent } from "./pages.component";
import { ProgressComponent } from "./progress/progress.component";
import { Graficas1Component } from "./graficas1/graficas1.component";
import { AccountSettingsComponent } from "./account-settings/account-settings.component";
import { AuthGuard } from "../guards/auth.guard";
import { BuyLicenceComponent } from "./buy-licence/buy-licence.component";
import { LicenceGuard } from "../guards/licence.guard";
import { Modules } from "../enums/modules.enum";
import { AdminGuard } from "../guards/admin.guard";
var ɵ0 = { breadcumbs: "Progreso" }, ɵ1 = { breadcumbs: "Graficas" }, ɵ2 = { breadcumbs: "Cuenta" }, ɵ3 = { breadcumbs: "Licencia" }, ɵ4 = { breadcumbs: "" }, ɵ5 = { breadcumbs: "Billetera", module: Modules.Wallet }, ɵ6 = { breadcumbs: "Administración" }, ɵ7 = { breadcumbs: "Historial", module: Modules.History }, ɵ8 = { breadcumbs: "Centro de pagos", module: Modules.Payment }, ɵ9 = { breadcumbs: "Perfil" }, ɵ10 = { breadcumbs: "Referidos", module: Modules.Referrals }, ɵ11 = { breadcumbs: "Tienda", module: Modules.Store }, ɵ12 = { breadcumbs: "Soporte" }, ɵ13 = { breadcumbs: "Carga masiva", module: Modules.Massive }, ɵ14 = { breadcumbs: "Servicios" }, ɵ15 = { breadcumbs: "Licencias" }, ɵ16 = { breadcumbs: "Usuarios" };
var pagesRoutes = [
    {
        path: "",
        component: PagesComponent,
        children: [
            {
                path: "progress",
                component: ProgressComponent,
                canActivate: [AuthGuard],
                data: ɵ0,
            },
            {
                path: "graficas1",
                component: Graficas1Component,
                canActivate: [AuthGuard],
                data: ɵ1,
            },
            {
                path: "account-settings",
                component: AccountSettingsComponent,
                canActivate: [AuthGuard],
                data: ɵ2,
            },
            {
                path: "buy-licence",
                component: BuyLicenceComponent,
                canActivate: [AuthGuard],
                data: ɵ3,
            },
            { path: "", redirectTo: "/dashboard", pathMatch: "full" },
        ],
    },
    {
        path: "dashboard",
        component: PagesComponent,
        loadChildren: "../pages/dashboard/dashboard.module#DashboardModule",
        canActivate: [AuthGuard],
        data: ɵ4,
    },
    {
        path: "wallet",
        component: PagesComponent,
        loadChildren: "../pages/wallet/wallet.module#WalletModule",
        canActivate: [AuthGuard],
        // LicenceGuard
        data: ɵ5,
    },
    {
        path: "admin",
        component: PagesComponent,
        loadChildren: "../pages/admin/admin.module#AdminModule",
        // loadChildren: () => AdminModule,
        canActivate: [AuthGuard],
        data: ɵ6,
    },
    {
        path: "history",
        component: PagesComponent,
        loadChildren: "../pages/history/history.module#HistoryModule",
        canActivate: [AuthGuard],
        // , LicenceGuard
        data: ɵ7,
    },
    {
        path: "payment-center",
        component: PagesComponent,
        loadChildren: "../pages/payment-center/payment-center.module#PaymentCenterModule",
        canActivate: [AuthGuard, LicenceGuard],
        data: ɵ8,
    },
    {
        path: "profile",
        component: PagesComponent,
        loadChildren: "../pages/profile/profile.module#ProfileModule",
        canActivate: [AuthGuard],
        data: ɵ9,
    },
    {
        path: "referrals",
        component: PagesComponent,
        loadChildren: "../pages/referrals/referrals.module#ReferralsModule",
        canActivate: [AuthGuard, LicenceGuard],
        data: ɵ10,
    },
    {
        path: "store",
        component: PagesComponent,
        loadChildren: "../pages/store/store.module#StoreModule",
        canActivate: [AuthGuard, LicenceGuard],
        data: ɵ11,
    },
    {
        path: "support",
        component: PagesComponent,
        loadChildren: "../pages/support/support.module#SupportModule",
        canActivate: [AuthGuard],
        data: ɵ12,
    },
    {
        path: "massive-load",
        component: PagesComponent,
        loadChildren: "../pages/massive-load/masive-load.module#MasiveLoadModule",
        canActivate: [AuthGuard, LicenceGuard],
        data: ɵ13,
    },
    {
        path: "services",
        component: PagesComponent,
        loadChildren: "../pages/services/services.module#ServicesModule",
        canActivate: [AuthGuard],
        data: ɵ14,
    },
    {
        path: "create-licence",
        component: PagesComponent,
        loadChildren: "../pages/licences/licences.module#LicencesModule",
        canActivate: [AuthGuard],
        data: ɵ15,
    },
    {
        path: "users",
        component: PagesComponent,
        loadChildren: "../pages/users/users.module#UsersModule",
        canActivate: [AuthGuard, AdminGuard],
        data: ɵ16,
    },
];
export var PAGES_ROUTES = RouterModule.forChild(pagesRoutes);
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16 };
