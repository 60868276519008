var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { HttpParams } from "@angular/common/http";
import { FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
//import * as bcrypt from "bcryptjs";
import { ApiService } from "../../services/api/api.service";
import { LoginService } from "../../services/login/login.service";
import { StorageService } from "../../services/storage/storage.service";
var RegisterDriverComponent = /** @class */ (function () {
    function RegisterDriverComponent(formBuilder, loginService, storageService, route, _api, router) {
        this.formBuilder = formBuilder;
        this.loginService = loginService;
        this.storageService = storageService;
        this.route = route;
        this._api = _api;
        this.router = router;
        this.submitted = false;
        this.failedLogin = false;
        this.typeUser = "user";
        this.registerOK = false;
        this.licenseId = "1";
        this.step = 1;
        this.fotosObligatorias = false;
        this.fotosObligatorias1 = false;
        this.paises = [];
        this.defaultPhone = "507";
        this.emailRegistrado = false;
        this.isLoading = false;
    }
    RegisterDriverComponent.prototype.ngOnInit = function () {
        init_plugins();
        this.loadForm();
        if (localStorage.getItem("user") || localStorage.getItem("user") !== null) {
            this.route.navigate(["/dashboard"]);
        }
        this.validateLicence();
        this.getPaises();
    };
    RegisterDriverComponent.prototype.onPhotoSelected = function (event) {
        var _this = this;
        if (event.target.files && event.target.files[0]) {
            this.file = event.target.files[0];
            // image preview
            var reader_1 = new FileReader();
            reader_1.onload = function (e) { return __awaiter(_this, void 0, void 0, function () {
                var imgBase64Path;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, reader_1.result];
                        case 1:
                            imgBase64Path = _a.sent();
                            this.photoSelected = imgBase64Path;
                            return [2 /*return*/];
                    }
                });
            }); };
            reader_1.readAsDataURL(this.file);
            //console.log(reader);
        }
    };
    RegisterDriverComponent.prototype.onPhotoSelected1 = function (event) {
        var _this = this;
        if (event.target.files && event.target.files[0]) {
            this.file1 = event.target.files[0];
            // image preview
            var reader1_1 = new FileReader();
            reader1_1.onload = function (e) { return __awaiter(_this, void 0, void 0, function () {
                var imgBase64Path1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, reader1_1.result];
                        case 1:
                            imgBase64Path1 = _a.sent();
                            this.photoSelected1 = imgBase64Path1;
                            return [2 /*return*/];
                    }
                });
            }); };
            reader1_1.readAsDataURL(this.file1);
        }
    };
    RegisterDriverComponent.prototype.validateLicence = function () {
        var _this = this;
        if (this.router.queryParams["_value"] &&
            this.router.queryParams["_value"].licenseKey) {
            this.licenseId = this.router.queryParams["_value"].licenseKey;
        }
        this._api.licences().subscribe(function (lic) {
            lic["licences"].forEach(function (element) {
                if (element.id === _this.licenseId) {
                    _this.licenseId = element.id;
                }
            });
        });
        console.log(this.licenseId);
    };
    /** Obtener paises para código telélefono */
    RegisterDriverComponent.prototype.getPaises = function () {
        var _this = this;
        //this.loginService
        //.paises("http://www.api.kamgus.com/v2/general/flags")
        this._api.genericGet("/countries/flags", "TOV2")
            .subscribe(function (respuesta) {
            console.log("Listado Paises");
            console.log(respuesta);
            _this.paises = respuesta;
        }, function (err) {
            console.log(err);
        });
        /*.subscribe((paises: any[]) => {
            this.paises = paises;
          });*/
    };
    RegisterDriverComponent.prototype.loadForm = function () {
        this.formData = this.formBuilder.group({
            nombre: ["", [Validators.required]],
            celular: ["", [Validators.required]],
            documento_entidad: [0, [Validators.required]],
            email: ["", [Validators.required, Validators.email]],
            reTypeEmail: ["", [Validators.required, Validators.email]],
            company: ["", []],
            paises: ["", []],
        });
        this.formDataEmail = this.formBuilder.group({
            password: ["", [Validators.required]],
            retypePassword: ["", [Validators.required]],
            terms: ["", [Validators.required]],
        });
    };
    //send() {
    RegisterDriverComponent.prototype.crearUsuario = function () {
        var _this = this;
        this.submitted = true;
        this.isLoading = true;
        var password = this.formDataEmail.value.password;
        var params = new FormData();
        params.append("nombres", this.formData.value.nombre);
        params.append("apellidos", ".");
        params.append("telefono", this.formData.value.celular);
        params.append("email", this.formData.value.email);
        params.append("password", password);
        params.append("tipo_documento_id", "1");
        params.append("cedula", this.file1);
        params.append("licencia", this.file);
        params.append("password_confirmation", password);
        this._api.registerDriver(params).subscribe(function (data) {
            _this.isLoading = false;
            // console.log(data);
            console.log("Usuario Creado");
            _this.registerOK = true;
            console.log(data);
            _this.route.navigate(["/thankyou-page-driver"]);
        }, function (err) {
            _this.isLoading = false;
            console.log(err);
            _this.emailRegistrado = true;
        });
        /*
        this.loginService
          .crearUsuario({
            RegistroForm: {
              nombre: this.formData.value.nombre,
              apellidos: "",
              celular: this.formData.value.celular,
              tipo_documento_id: "1",
              //password: passwordHash,
              password: this.formDataEmail.value.password,
              documento_entidad: "",
              url_cedula: this.photoSelected1,
              licencia_foto: this.photoSelected,
              email: this.formData.value.email,
              pais_id: "176",
            },
          })
          .subscribe(
            (respuesta) => {
              console.log("Usuario Creado");
              console.log(respuesta);
              this.route.navigate(["/thankyou-page-driver"]);
            },
            (err) => {
              console.log(err);
              this.emailRegistrado = true;
            }
          );
          */
    };
    Object.defineProperty(RegisterDriverComponent.prototype, "f", {
        get: function () {
            return this.formData.controls;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RegisterDriverComponent.prototype, "g", {
        get: function () {
            return this.formDataEmail.controls;
        },
        enumerable: true,
        configurable: true
    });
    RegisterDriverComponent.prototype.next = function (step) {
        var _this = this;
        //console.log(this.formData);
        this.submitted = true;
        if (step === 1) {
            if (!this.formData.valid) {
                return;
            }
            if (!this.formData.valid ||
                this.photoSelected == null ||
                this.photoSelected1 == null) {
                this.fotosObligatorias = true;
                this.fotosObligatorias1 = true;
                return;
            }
            this.photoSelected == null && this.photoSelected1 == null;
            //console.log("FILA ------------ ", this.file);
            //console.log("FILA 1------------ ", this.file1);
            /**** Agregado */
            this.isLoading = true;
            var params = new HttpParams()
                .set("email", this.formData.value.email)
                .set("telefono", this.formData.value.celular)
                .set("type", "driver");
            this._api
                .validateUser(params)
                .subscribe(function (data) {
                _this.isLoading = false;
                _this.step += 1;
            }, function (err) {
                _this.isLoading = false;
                _this.emailRegistrado = true;
            });
            /*
              this._api
              .genericPostNew("http://www.api.kamgus.com/v2/general/vemail", {
                LoginForm: {
                  email: this.formData.value.email,
                  celular: this.formData.value.celular,
                },
              })
              .subscribe(
                (users: any) => {
                  //console.log(users.data);
                  this.step += 1;
                },
                (err) => {
                  console.log(err);
                  this.emailRegistrado = true;
                }
              );
            */
            /***** ---------------- */
            /*if (
              !this.formData.valid ||
              this.photoSelected == null ||
              this.photoSelected1 == null
            ) {
              this.fotosObligatorias = true;
              this.fotosObligatorias1 = true;
              return;
            }
            this.photoSelected == null && this.photoSelected1 == null;
            console.log(this.file);*/
            //this.step += 1;
        }
        if (step === 2) {
            if (!this.formDataEmail.valid) {
                return;
            }
            else if (this.g.password.value !== this.g.retypePassword.value) {
                return;
            }
            else {
                this.crearUsuario();
                //this.send();
            }
        }
    };
    RegisterDriverComponent.prototype.inputValidator = function (event) {
        this.emailRegistrado = false;
    };
    return RegisterDriverComponent;
}());
export { RegisterDriverComponent };
/*
envio anterior (send)
send() {
    this.submitted = true;

    if ( !this.formData.valid ) {
      return;
    }
    const request = this.updateModel(this.formData.value );
    console.log( request );
    this.loginService.register( request ).subscribe( response => {
      console.log('response', response);
      this.createLicense( response['id_user'] );
    }, err => {
      console.log('err', err);
      this.errorMessage = err;
    });
    console.log( this.formData.value );
  }


datos
"customer": {
          "nombres": this.formData.value.nombre,
          "apellidos": this.formData.value.apellido,
          "telefono": this.formData.value.celular,
          "cedula": this.formData.value.celular
          "pasaporte": file,
          "licencia": file,
        },
       "user": {
          "email": this.formDataEmail.value.email,
          "password": passwordHash,
        }








"nombres": this.formData.value.nombre,
          "apellidos": "NoHayApellido",
          "telefono": this.formData.value.celular,
          "cedula": this.file.name,
          "pasaporte": this.file1.name,
          "licencia": this.file1.name,
          "email": this.formDataEmail.value.email,
          "password": "SinPasword",








*/
