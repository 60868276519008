import { Pipe, PipeTransform } from '@angular/core';

import { environment } from "../../environments/environment";
const base_url_profile_image = environment.base_url_profile_image;

@Pipe({
  name: 'image_user'
})
export class Image_userPipe implements PipeTransform {

  transform(value: any): any {
    
    if(value != null && value != ""){
      return `${base_url_profile_image}/${value}`;
    }else{
      return 'assets/icons/user_white.png';
    }

  }

}
