import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../services/shared/shared.service';
import { ApiService } from '../../services/api/api.service';

@Component({
  selector: 'app-buy-licence',
  templateUrl: './buy-licence.component.html',
  styleUrls: ['./buy-licence.component.css'],
  providers: [ ApiService ]
})
export class BuyLicenceComponent implements OnInit {

  constructor( private _api: ApiService, private sharedService: SharedService, ) { }

  licences: any;
  actual: any;
  user;
  ngOnInit() {
    this.user = this.sharedService.decodeJwt();
    console.log( this.user );
    this.loadLicences();
    this.loadLicence();
  }

  loadLicences() {
    this._api.licences().subscribe( lic => {
      lic['licences'].forEach( element => {
        const setA = JSON.parse(  element.atributes );
        element.setAttributes = setA;
      });
      this.licences = lic['licences'];
      console.log( this.licences );
    });
  }

  loadLicence() {
    const params = new HttpParams()
    .set('licence', localStorage.getItem('licence') );
    this._api.validateLicence( params ).subscribe( ok => {
      this.actual = ok['licences'][0];
      console.log( this.actual);
    });
  }

  activateLicence( item ){

    const params = new HttpParams()
      .set('id_user', this.user.idusuarios )
      .set('id_licence', item.id );

    this._api.activateFreeLicence( params ).subscribe( licence=>{
      const paramss = new HttpParams()
        .set('id_user', this.user.idusuarios );
        this._api.getLicenceByUser( paramss ).subscribe( lic => {
          localStorage.setItem('licence', lic['licences'][0].licence);
          this.loadLicence();
        });

    });
  }

}


