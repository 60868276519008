import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
// import { BaseService } from "../commons/base.service";
import { tap } from "rxjs/operators";
var base_url = environment.genericThird;
var apikamgus_base_url = environment.apikamgus_base_url;
var LoginService = /** @class */ (function () {
    function LoginService(http) {
        this.http = http;
    }
    LoginService.prototype.login = function (params) {
        return this.http.post(environment.login, params);
    };
    /** Inicio de Sesión */
    LoginService.prototype.loginNew = function (params) {
        var url = apikamgus_base_url + "/login";
        return this.http.post(url, params).pipe(tap(function (resp) {
            localStorage.setItem("token", JSON.stringify(resp.token));
            localStorage.setItem("role", JSON.stringify(resp.role));
            return resp;
        }));
    };
    /** Obtener Paises */
    LoginService.prototype.paises = function (url) {
        return this.http.get(url);
    };
    LoginService.prototype.licences = function () {
        return this.http.get(environment.licences);
    };
    LoginService.prototype.register = function (params) {
        return this.http.post(environment.register, params);
    };
    /** Registrar nuevo usuario */
    LoginService.prototype.crearUsuario = function (params) {
        return this.http.post(environment.crearUsuario, params);
    };
    /** Registrar nuevo usuario desde Administrador */
    LoginService.prototype.crearUser = function (params) {
        return this.http.post(environment.crearUser, params);
    };
    LoginService.prototype.registerCompanyUser = function (params) {
        var url = apikamgus_base_url + "/registration/companies";
        return this.http.post(url, params).pipe(tap(function (resp) {
            return resp;
        }));
    };
    return LoginService;
}());
export { LoginService };
