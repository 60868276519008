import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {  Router } from '@angular/router';
import { LoginService } from '../../services/login/login.service';
import { ApiService } from '../../services/api/api.service';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-recuperar-clave',
  templateUrl: './recuperar-clave.component.html',
  styleUrls: ['./recuperar-clave.component.css'],
  providers: [ LoginService ]
})
export class RecuperarClaveComponent implements OnInit {

  formData : FormGroup;
  msg      : string; 
  isLoading: boolean = false;

  constructor( private formBuilder: FormBuilder,
    private loginService: LoginService,
    private route: Router,
    private _api: ApiService,
    ) { }

  ngOnInit() {
    this.loadForm();
  }

  loadForm() {
    this.formData = this.formBuilder.group({
      user: [ '', [ Validators.required, Validators.email ] ],
    });
  }

  send(){
    
    this.isLoading = true;

    let email = this.formData.value.user;

    this._api.forgotPassword(email).subscribe(data => {
      this.msg = data.msg;
      this.isLoading = false;
    });

  }

}
