const END_POINT = "https://app.kamgus.com/api/index.php/kamgus";
const PAY = "https://app.kamgus.com/api/index.php";
const END_POINT2 = "http://www.api.kamgus.com/v2/general";
const END_POINT3 = "https://www.api.kamgus.com/usuario-v2/index.php/kamgus";


const APIKAMGUS = "https://apikamgusv2.kamgus.com/api";
const BASE_URL_IMG = "https://apikamgusv2.kamgus.com/documentos";
const BASE_URL_IMG_USER = "https://apikamgusv2.kamgus.com/usuarios";
const PROFILE_PATH = "https://apikamgusv2.kamgus.com/profile/images";
export const environment = {
  //crearUsuario: END_POINT2 + "/registro",
  apikamgus_base_url: APIKAMGUS + "/v2",
  base_url_path: BASE_URL_IMG,
  base_url_path_user: BASE_URL_IMG_USER,
  base_url_profile_image: PROFILE_PATH,

  crearUsuario: END_POINT2 + "/driverRegister",
  crearUser: END_POINT2 + "/userRegister",
  production: false,
  generic: END_POINT,
  genericDos: END_POINT2,
  genericThird: END_POINT3,
  login: END_POINT + "/login",
  licences: END_POINT + "/licences",
  licencesModules: END_POINT + "/licenceModules",
  validateLicence: END_POINT + "/validateLicence",
  activateFreeLicence: END_POINT + "/activateFreeLicence",
  getLicenceByUser: END_POINT + "/getLicenceByUser",
  register: END_POINT + "/register",
  payCard: PAY + "/Transactions/preAuth",
  web: "https://www.kamgus.com",
  google: "https://play.google.com/store/apps/details?id=com.kamgusu.pa",
  ios: "https://apps.apple.com/us/app/id1495896386",
  googleDriver: "https://play.google.com/store/apps/details?id=com.kamgus.pa",
  iosDriver: "https://apps.apple.com/us/app/id1495896386",

  pkStripe: 'pk_test_51K6crsEumLY5hcKQ8p1xZ1eeBirue6dKTk8e2mUj7G78nWgeVPcCFORzRyEdpTWyr1xjHjz8PWNxC4cTb3uxFh1I00ajY5UcvI',
  users: {
    card: {
      setup_intent: ""
    }
  },
  support:{
    base: "/support",
    categories: "/support/categories",
  }
};
