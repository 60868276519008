import { HttpClient } from "@angular/common/http";
import { Injectable} from "@angular/core";
import { environment } from "../../../environments/environment";
import { tap } from "rxjs/operators";
import { Continue } from "../../interfaces/ValidatorInterface";
import { LicenseModules } from "../../interfaces/LicenseModulesInterface";
import { Countries } from '../../interfaces/CountryInterface';
import { UpdateProfileImage } from '../../interfaces/UserInterface';
import { UpdateProfileAnyUser } from '../../interfaces/UpdateAnyProfile';
import { Categories } from '../../interfaces/Categories';
import { SubCategories } from '../../interfaces/SubCategories';
import { Articles } from '../../interfaces/Articles';
import { OwnCreditCards } from '../../interfaces/CreditCards';
import { Seti } from '../../interfaces/Seti';
import { TransportTypeInterface } from '../../interfaces/TransportTypeInterface';
import { MsgInterface } from '../../interfaces/MsgInterface';
import { SubCategoryWithArticlesInterface } from '../../interfaces/SubCategoryWithArticlesInterface';

const apikamgus_base_url = environment.apikamgus_base_url;

@Injectable()
export class ApiService {


  constructor(public http: HttpClient) {}

  get token(): string {
    return 'Bearer ' + JSON.parse(localStorage.getItem('token')) || '';
  }

  get headers() {
    return {
      headers: {
        'Authorization': this.token
      }
    }
  }

  genericDelete(url, withHeaders = "") {
    if(withHeaders === "TOV2"){
      return this.http.delete(environment.apikamgus_base_url + url, this.headers);
    }
    
    return this.http.delete(environment.apikamgus_base_url + url, this.headers);
  }
  genericPut(url, params, withHeaders = "") {
    if(withHeaders === "TOV2"){
      return this.http.put(environment.apikamgus_base_url + url, params, this.headers);
    }
    
    return this.http.put(environment.apikamgus_base_url + url, params, this.headers);
  }
  genericPost(url, params, withHeaders = "") {
    if(withHeaders === "TOV2"){
      return this.http.post(environment.apikamgus_base_url + url, params, this.headers);
    }
    return this.http.post(environment.apikamgus_base_url + url, params, this.headers);
  }

  genericPostNew(url, params) {//No usado
    return this.http.post(url, params);
  }

  genericGet(url, withHeaders = "") {
    if(withHeaders === "TOV2"){
      return this.http.get(environment.apikamgus_base_url + url, this.headers);
    }
    return this.http.get(environment.apikamgus_base_url + url, this.headers);
  }

  genericGetNew(url) {//No usado
    return this.http.get(environment.genericDos + url);
  }

  licences() {
    return this.http.get(environment.licences);
  }

  licencesModules(licence) {
    return this.http.post(environment.licencesModules, licence);
  }

  validateLicence(licence) {
    return this.http.post(environment.validateLicence, licence);
  }

  activateFreeLicence(licence) {
    return this.http.post(environment.activateFreeLicence, licence);
  }

  getLicenceByUser(licence) {
    return this.http.post(environment.getLicenceByUser, licence);
  }
  preAuthorization(licence) {
    return this.http.post(environment.payCard, licence);
  }

  /** API Generic Enviroment V2 */
  genericPostV2(url, params) {
    return this.http.post(environment.genericDos + url, params);
  }

  
  validateUser(params){

    const url = `${apikamgus_base_url}/verification/email`;

    return this.http.post(url, params).pipe(
      tap((resp: Continue) => {
        return resp;
      })
    );

  }

  // registrar usuario con apikamgus v2
  registerCustomer(params: any){

    const url =  `${apikamgus_base_url}/registration/customers`;

    return this.http.post(url, params).pipe(
      tap((resp: any) => {
        return resp;
      })
    );

  }
  registerDriver(params: any){

    const url =  `${apikamgus_base_url}/registration/drivers`;

    return this.http.post(url, params, {  
      reportProgress: true,  
    }).pipe(
      tap((resp: any) => {
        return resp;
      })
    );

  }
  deleteAccount(params: any){

    const url =  `${apikamgus_base_url}/driver/delete_account`;

    return this.http.post(url, params, {  
      reportProgress: true,  
    }).pipe(
      tap((resp: any) => {
        return resp;
      })
    );

  }

  licenceModules(){

    const url = `${apikamgus_base_url}/license/modules`;

    return this.http.get<LicenseModules>(url, this.headers).pipe(
      tap((resp) => {
        return resp;
      })
    );

  }

  loadCountries(){

    const url = `${apikamgus_base_url}/countries`;

    return this.http.get<Countries>(url, this.headers).pipe(
      tap((resp) => {
        return resp;
      })
    );

  }

  updateProfileImage(imagen: File){
    
    const url =  `${apikamgus_base_url}/update/user/profile/image`;

    const formData = new FormData();
    formData.append('image', imagen);

    return this.http.post<UpdateProfileImage>(url, formData, this.headers).pipe(
      tap((resp: any) => {
        return resp;
      })
    );

  }

  updateProfile(params){

    const url =  `${apikamgus_base_url}/update/user/profile`;

    return this.http.put<UpdateProfileAnyUser>(url, params, this.headers).pipe(
      tap((resp) => {
        return resp;
      })
    );

  }

  getAllCategories(){

    const url = `${apikamgus_base_url}/categories`;

    return this.http.get<Categories>(url).pipe(
      tap((resp) => {
        return resp;
      })
    );

  }

  getCategoriesOfASubcategory( id: number ){

    const url = `${apikamgus_base_url}/categories/${id}/subcategories`;

    return this.http.get<SubCategoryWithArticlesInterface>(url).pipe(
      tap((resp:any) => {
        return resp;
      })
    );

  }

  getAllArticles(){
    const url = `${apikamgus_base_url}/articles`;

    return this.http.get<Articles>(url).pipe(
      tap((resp) => {
        return resp;
      })
    );
  }

  getAllSubCategories(){
    const url = `${apikamgus_base_url}/subcategories`;

    return this.http.get<SubCategories>(url).pipe(
      tap((resp) => {
        return resp;
      })
    );
  }

  getCreditsCard(){
    const url = `${apikamgus_base_url}/payment/methods`;

    return this.http.get<OwnCreditCards>(url, this.headers).pipe(
      tap((resp) => {
        return resp;
      })
    );
  }

  getSeti(){
    const url = `${apikamgus_base_url}/setup/intent`;

    return this.http.post<Seti>(url, {}, this.headers).pipe(
      tap((resp) => {
        return resp;
      })
    );
  }

  servicexArticle(params){

    const url = `${apikamgus_base_url}/servicesxarticles`;

    return this.http.post<MsgInterface>(url, params, this.headers).pipe(
      tap( (resp) => {
        return resp;
      })
    );

  }

  getVehicleTypes(){

    const url = `${apikamgus_base_url}/typestransports`;

    return this.http.get<TransportTypeInterface>(url).pipe(
      tap((resp) => {
        return resp;
      })
    );

  }

  addCards(params){

    const url = `${apikamgus_base_url}/new/payment/method`;

    return this.http.post<MsgInterface>(url, params, this.headers).pipe(
      tap( (resp) => {
        return resp;
      })
    );

  }

  forgotPassword(email){

    const url = `${apikamgus_base_url}/forgot/password?email=${email}`;

    return this.http.get<MsgInterface>(url).pipe(
      tap( (resp) => {
        return resp;
      })
    );

  }

  resetPassword(params){

    const url = `${apikamgus_base_url}/reset/password`;

    return this.http.post<MsgInterface>(url, params).pipe(
      tap( (resp) => {
        return resp;
      })
    );

  }


}
