<div class="text-center pd-thank-you">
  <br><br>
  <h1 class="color-blue__titulothankscompany">GRACIAS POR TU SOLICITUD</h1>
  <br>
  <img src="../../assets/images/thanks.png" style="padding-bottom: 31px; width: 200px;">
  <br>
  <p class="text-thankscompany__driver text-justify  text">Sus datos has sido enviados a nuestro equipo de adminsión,  estarán respondiendo su solicitud en un lapso no mayor a 72 horas, no podrás acceder a tu app de conductor hasta no ser  validad como conductor</p>
  <br>
  <h6 class="text-subtitulo__driver">DESCARGA NUESTRA APP PARA CONDUCTORES</h6>
   <!--APP CARDS-->
  <div class="row ocultar2 contenedorAppbtn" style="margin: 0 auto; padding-bottom: 14%;">
    <div class="col-md-6 text-right | responsive-thank">
      <a href="https://play.google.com/store/apps/details?id=com.kamgus.pa">
        <img src="../../../assets/images/google.png" style="height: 40px;" >
      </a>
    </div>
    <div class="col-md-6 text-left | responsive-thank">
      <a href="">
        <img src="../../../assets/images/ios.png" style="height: 40px;">
      </a>
    </div>
  </div>
  <!--APP CARDS-->
   <!--APP CARDS TELEFONO-->
   <div class="row ocultar">
    <div class="col-md-8 mb-3 mt-3">
      <a href="https://play.google.com/store/apps/details?id=com.kamgusu.pa">
        <img src="../../../assets/images/google.png" style="height: 40px" />
      </a>
    </div>
    <div class="col-md-8 mb-3">
      <a [href]="ios">
        <img src="../../../assets/images/ios.png" style="height: 40px" />
      </a>
    </div>
  </div>
  
   <!--APP CARDS TELEFONO-->
  <br>
  <div class="row mb-5">
    
    <div class="col-md-12">
      <a [href]="web" class="btn  btn-facebook__driver btn-lg" style="color:#fff"> 
        Volver a página web
      </a>
    </div>
  </div>
</div>