import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { IfObservable } from 'rxjs/observable/IfObservable';
import { Modules } from '../enums/modules.enum';
import { ApiService } from '../services/api/api.service';
import { SharedService } from '../services/shared/shared.service';
import { Module } from "../interfaces/LicenseModulesInterface";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class LicenceGuard implements CanActivate {

  modules: Module[] = [];
  active = false;
  finishes = false;
  // user;
  constructor(
    private readonly router: Router, 
    private _api: ApiService, 
    private sharedService: SharedService
  ){ }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>|boolean {
    // console.log( route );
    // this.user = this.sharedService.decodeJwt();
    // const licencia = localStorage.getItem('licence');
    const role = JSON.parse(localStorage.getItem("role"));

    if( role === 'Administrador'){
      return true;
    }
    // const params = new HttpParams()
    // .set('licence', JSON.parse(licencia).licence );
    // this._api.licencesModules( params ).subscribe( ok => {
    //   this.modules  = ok['modules'];
    //   if (this.modules !== undefined) {
    //     let cont = 0;
    //     this.modules.forEach(element => {
    //         if ( route.data && route.data.module === element.module ) {
    //           this.active = true;
    //         }
    //         cont++;
    //         if ( cont === this.modules.length) {
    //           this.finishes = true;
    //           if ( !this.active ) {
    //             //this.router.navigate(['/buy-licence']);
    //             console.log("licencia")
    //           }
    //         }
    //     });
    //   }

    // }, err => {
    //   //this.router.navigate(['/buy-licence']);
    //   console.log("licencia error")
    // });

    // this._api.licenceModules().subscribe(data => {
      
    //   this.modules = data.modules;

    //   if(data.modules.length <= 0){
    //     console.log("No tienes licencia");
    //   }
      
    // });
  

    // for (let index = 0; index < this.modules.length; index++) {
    //   // var active = false;
    //   var element = this.modules[index].module;
    //   console.log(element);

    //   if ( route.data && route.data.module === element ) {
    //     this.active = true;
    //   }
    // }
    
    // if( this.active ){
    //     return true;
    // }

    return this._api.licenceModules().pipe(map((data) => {

      this.modules = data.modules;

      if(data.modules.length <= 0){
        console.log("No tienes licencia");
        return false;
      }

      for (let index = 0; index < this.modules.length; index++) {
        // var active = false;
        var element = this.modules[index].module;
        // console.log(element);
        if ( route.data && route.data.module === element ) {
          return true;
        }
      }

    }));
  
  }
  
}

