<div class="row cont-login login-company">
  <div class="col-md-6 registerDriver pd-forms-login text-center no-display-movil">
    <img class="img-80" src="../../assets/images/logo-white2.png" />
    <div class="spacer-100"></div>
    <h3 class="color-white-driver">
     
    </h3>

  </div>

  <div class="col-md-6 contenedorRegisterDriver cont-white pd-forms-registerdriver">
    <div class="card contenedorFormresponsive">
      <form class="form-horizontal form-material" [formGroup]="formData">
        <h3 class="box-title m-t-40 m-b-0 color-blue text-center titulo | titulo-register--driver">
          Eliminar cuenta
        </h3>
        <br />

        <div class="alert alert-danger" *ngIf="errorMessage !== undefined">
          <i class="ti-close"></i> {{ errorMessage.error.msg }}
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="form-group" *ngIf="typeUser === 'company'">
          <div class="col-xs-12">
            <label class="color-blue">Nombre empresa</label>
            <input class="form-control input-register" type="text" required="" placeholder="Nombre de la empresa"
              formControlName="company" />
            <div *ngIf="submitted && f.company.errors" class="invalid-feedback">
              <div *ngIf="f.company.errors.required">
                * El nombre de la empresa es requerido
              </div>
            </div>
          </div>
        </div>



 
        <div class="form-group">
          <div class="col-xs-12">
            <label class="color-blue">Correo</label>
            <input class="form-control input-register" type="text" required="" placeholder="Email"
              formControlName="email" (input)="inputValidator($event)" autofocus="true" />

            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
              <div *ngIf="f.email.errors.required">* El email es requerido</div>
            </div>
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
              <div *ngIf="f.email.errors.email">
                * No es un formato de correo válido
              </div>
            </div>
            <div class="invalid-feedback" *ngIf="emailRegistrado">
              * Email o Teléfono Registrado intente con otro
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="col-xs-12">
            <label class="color-blue">Contraseña</label>
            <input class="form-control input-register" type="password" required="" placeholder="Contraseña"
              formControlName="password" (input)="inputValidator($event)" />

            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
              <div *ngIf="f.password.errors.required">* El password es requerido</div>
            </div>
            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
              <div *ngIf="f.password.errors.password">
                * No es un formato de correo válido
              </div>
            </div>
          
          </div>
        </div>
    
        <div class="col-xs-12">
          <button class="btn-info--registerdriver" type="submit" (click)="clickDeleteAccount()">
            <div *ngIf="isLoading" class="lds-dual-ring"></div>
            <span>Eliminar</span>
          </button>
        </div>

      </form>
      <!---->
    </div>
  </div>
</div>