import { HttpParams } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
//import * as bcrypt from "bcryptjs";
import { ApiService } from "../../services/api/api.service";
import { LoginService } from "../../services/login/login.service";
import { StorageService } from "../../services/storage/storage.service";

declare function init_plugins();
interface HtmlInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
  target1: HTMLInputElement & EventTarget;
}
@Component({
  selector: "app-register-driver",
  templateUrl: "./register-driver.component.html",
  styleUrls: ["./register-driver.component.css"],
  providers: [ApiService],
})
export class RegisterDriverComponent implements OnInit {
  file: File;
  photoSelected: string | ArrayBuffer;
  file1: File;
  photoSelected1: string | ArrayBuffer;
  formData: FormGroup;
  formDataEmail: FormGroup;
  submitted = false;
  failedLogin = false;
  typeUser = "user";
  errorMessage;
  registerOK = false;
  licenseId = "1";
  step = 1;
  fotosObligatorias = false;
  fotosObligatorias1 = false;
  public paises: any[] = [];
  defaultPhone = "507";
  emailRegistrado = false;
  photoSel;
  isLoading:boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private storageService: StorageService,
    private route: Router,
    private _api: ApiService,
    private router: ActivatedRoute
  ) {}

  ngOnInit() {
    init_plugins();
    this.loadForm();
    if (localStorage.getItem("user") || localStorage.getItem("user") !== null) {
      this.route.navigate(["/dashboard"]);
    }
    this.validateLicence();
    this.getPaises();
  }
  onPhotoSelected(event: HtmlInputEvent): void {
    if (event.target.files && event.target.files[0]) {
      this.file = event.target.files[0];
      // image preview
      const reader = new FileReader();
      reader.onload = async (e) => {
        const imgBase64Path = await reader.result;
        this.photoSelected = imgBase64Path;
        //console.log(this.photoSelected);
      };
      reader.readAsDataURL(this.file);
      //console.log(reader);
    }
  }
  onPhotoSelected1(event: HtmlInputEvent): void {
    if (event.target.files && event.target.files[0]) {
      this.file1 = event.target.files[0];
      // image preview
      const reader1 = new FileReader();
      reader1.onload = async (e) => {
        const imgBase64Path1 = await reader1.result;
        this.photoSelected1 = imgBase64Path1;
        //console.log(this.photoSelected1);
      };
      reader1.readAsDataURL(this.file1);
    }
  }

  validateLicence() {
    if (
      this.router.queryParams["_value"] &&
      this.router.queryParams["_value"].licenseKey
    ) {
      this.licenseId = this.router.queryParams["_value"].licenseKey;
    }
    this._api.licences().subscribe((lic) => {
      lic["licences"].forEach((element) => {
        if (element.id === this.licenseId) {
          this.licenseId = element.id;
        }
      });
    });
    console.log(this.licenseId);
  }

  /** Obtener paises para código telélefono */
  getPaises() {
    //this.loginService
    //.paises("http://www.api.kamgus.com/v2/general/flags")
    this._api.genericGet("/countries/flags", "TOV2") 
      .subscribe(
        (respuesta: any[]) => {
          console.log("Listado Paises");
          console.log(respuesta);
          this.paises = respuesta;
        },
        (err) => {
          console.log(err);
        }
      );
    /*.subscribe((paises: any[]) => {
        this.paises = paises;
      });*/
  }

  loadForm() {
    this.formData = this.formBuilder.group({
      nombre: ["", [Validators.required]],
      celular: ["", [Validators.required]],
      documento_entidad: [0, [Validators.required]],
      email: ["", [Validators.required, Validators.email]],
      reTypeEmail: ["", [Validators.required, Validators.email]],
      company: ["", []],
      paises: ["", []],
      //terms: ["", [Validators.required]],
    });
    this.formDataEmail = this.formBuilder.group({
      password: ["", [Validators.required]],
      retypePassword: ["", [Validators.required]],
      terms: ["", [Validators.required]],
    });
  }

  //send() {
  crearUsuario() {
    this.submitted = true;
    this.isLoading = true;
    const password = this.formDataEmail.value.password;
    let params = new FormData();
      params.append("nombres", this.formData.value.nombre)
      params.append("apellidos", ".")
      params.append("telefono", this.formData.value.celular)
      params.append("email", this.formData.value.email)
      params.append("password", password)
      params.append("tipo_documento_id", "1")
      params.append("cedula", this.file1)
      params.append("licencia", this.file)
      params.append("password_confirmation",  password);
  
    this._api.registerDriver(params).subscribe(
      (data) => {
        this.isLoading = false;
        // console.log(data);
        console.log("Usuario Creado");
        this.registerOK = true;
        console.log(data);
          this.route.navigate(["/thankyou-page-driver"]);
      },
      (err) => {
        this.isLoading = false;
        console.log(err);
        this.emailRegistrado = true;
      }
    );
    /*
    this.loginService
      .crearUsuario({
        RegistroForm: {
          nombre: this.formData.value.nombre,
          apellidos: "",
          celular: this.formData.value.celular,
          tipo_documento_id: "1",
          //password: passwordHash,
          password: this.formDataEmail.value.password,
          documento_entidad: "",
          url_cedula: this.photoSelected1,
          licencia_foto: this.photoSelected,
          email: this.formData.value.email,
          pais_id: "176",
        },
      })
      .subscribe(
        (respuesta) => {
          console.log("Usuario Creado");
          console.log(respuesta);
          this.route.navigate(["/thankyou-page-driver"]);
        },
        (err) => {
          console.log(err);
          this.emailRegistrado = true;
        }
      );
      */
  }

  get f() {
    return this.formData.controls;
  }

  get g() {
    return this.formDataEmail.controls;
  }

  next(step) {
    //console.log(this.formData);
    this.submitted = true;
    if (step === 1) {
      if (!this.formData.valid) {
        return;
      }
      if (
        !this.formData.valid ||
        this.photoSelected == null ||
        this.photoSelected1 == null
      ) {
        this.fotosObligatorias = true;
        this.fotosObligatorias1 = true;
        return;
      }
      this.photoSelected == null && this.photoSelected1 == null;
      //console.log("FILA ------------ ", this.file);
      //console.log("FILA 1------------ ", this.file1);
      /**** Agregado */    
      this.isLoading = true;

      const params = new HttpParams()
        .set("email", this.formData.value.email)
        .set("telefono", this.formData.value.celular)
        .set("type","driver")
      this._api
        .validateUser(params)
        .subscribe(
          (data: any) => {
            this.isLoading = false;
            this.step += 1;
          },
          (err: any) => {
            this.isLoading = false;
            this.emailRegistrado = true;
          }
        );
        /*
          this._api
          .genericPostNew("http://www.api.kamgus.com/v2/general/vemail", {
            LoginForm: {
              email: this.formData.value.email,
              celular: this.formData.value.celular,
            },
          })
          .subscribe(
            (users: any) => {
              //console.log(users.data);
              this.step += 1;
            },
            (err) => {
              console.log(err);
              this.emailRegistrado = true;
            }
          );
        */
      /***** ---------------- */
      /*if (
        !this.formData.valid ||
        this.photoSelected == null ||
        this.photoSelected1 == null
      ) {
        this.fotosObligatorias = true;
        this.fotosObligatorias1 = true;
        return;
      }
      this.photoSelected == null && this.photoSelected1 == null;
      console.log(this.file);*/

      //this.step += 1;
    }
    if (step === 2) {
      if (!this.formDataEmail.valid) {
        return;
      } else if (this.g.password.value !== this.g.retypePassword.value) {
        return;
      } else {
        this.crearUsuario();
        //this.send();
      }
    }
  }

  inputValidator(event: any) {
    this.emailRegistrado = false;
  }
}
/*
envio anterior (send)
send() {
    this.submitted = true;

    if ( !this.formData.valid ) {
      return;
    }
    const request = this.updateModel(this.formData.value );
    console.log( request );
    this.loginService.register( request ).subscribe( response => {
      console.log('response', response);
      this.createLicense( response['id_user'] );
    }, err => {
      console.log('err', err);
      this.errorMessage = err;
    });
    console.log( this.formData.value );
  }


datos
"customer": {
          "nombres": this.formData.value.nombre,
          "apellidos": this.formData.value.apellido,
          "telefono": this.formData.value.celular,
          "cedula": this.formData.value.celular
          "pasaporte": file,
          "licencia": file,
        },
       "user": {
          "email": this.formDataEmail.value.email,
          "password": passwordHash,
        }  








"nombres": this.formData.value.nombre,
          "apellidos": "NoHayApellido",
          "telefono": this.formData.value.celular,
          "cedula": this.file.name,
          "pasaporte": this.file1.name,
          "licencia": this.file1.name,
          "email": this.formDataEmail.value.email,
          "password": "SinPasword", 








*/
