import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { SharedService } from "../../services/shared/shared.service";
import jwt_decode from 'jwt-decode';

import { User, UserClass } from "../../interfaces/UserInterface";
import { SidebarService } from '../../services/shared/sidebar.service';
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styles: [],
  styleUrls: ["./header.component.css"],
  providers: [SharedService],
})
export class HeaderComponent implements OnInit {
  // user: any;
  // usuario: any;
  // name: any;
  // rol: any;
    user: UserClass;
    role: string;

  constructor(
    private router: Router, 
    private sharedService: SharedService,
    public _sb: SidebarService,
    ) {
    // this.rol = localStorage.getItem("rol");
  }

  ngOnInit() {
    if (localStorage.getItem("token")) {
      //this.user = this.sharedService.decodeJwt();
      // const token = localStorage.getItem("token");
      // this.usuario = jwt_decode(token);
      // console.log("usuario ----- ", this.usuario);
      this.user = {
        nombres:  '',
        apellidos: '',
        nombre_empresa: '',
        nombre_contacto: '',
      }

      this.ShowUser();
    }
  }
  logOut() {
    localStorage.clear();
    // this._sb.menu = [];
    this.router.navigate(['/login']);
  }

  ShowUser(){
    this.sharedService.showUser().subscribe(user => {
      this.user = user.data.user;
      this.role = user.data.role;
    });
  }
}
